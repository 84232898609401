import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ReportBookingStatus } from "src/app/service/models/enum/booking-status.enum";

@Component({
    selector: 'bt-report-booking',
    template: `
        <ng-container *ngIf="status">
            <button *ngIf="status === ReportBookingStatus.AVAILABLE" type="button" class="btn__regular btn btn__secondary btn__reportbooking"
                (click)="report()" [disabled]="reported">
                <ng-content></ng-content>
            </button>
            <div *ngIf="status === ReportBookingStatus.REPORTED" class="status__reported">
                <span class="status__reported-text">
                    <ng-content select="[reported]"></ng-content>
                </span>
            </div>
        </ng-container>
    `,
    styleUrls: ['./report-booking.component.scss']
})
export class ReportBookingComponent {
    @Input() status: ReportBookingStatus;
    @Input() reported: boolean;
    @Output() reportEvent = new EventEmitter();

    ReportBookingStatus = ReportBookingStatus;

    constructor() { }

    report() {
        this.reportEvent.emit();
    }
}
