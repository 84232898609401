import {Observable, Subscription} from "rxjs";
import {InjectionToken} from "@angular/core";
import {NgbActiveModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";

export const TAP_N_PAY_CONFIGURATION_TOKEN = new InjectionToken<TapNpayConfiguration>('TAP_N_PAY_CONFIGURATION');

export interface TapNpayConfiguration {
  environment: any,
  source: TapNpaySource,
  routeBT: string;
  routeNB: string;
  websocketUrl: string;
}

export interface TapNpayStart {
  paymentDetails: TapNpayPaymentDetails,
  bookingDetails?: TapNpayBookingDetails,
  skipAmountModal?: boolean,
  activeModal?: NgbActiveModal,
  currencySymbol?: string;
}

export interface TapNpayPaymentDetails {
  amount: number;
  bookingId: number;
  bbId: number;
  refundPaymentId: string;
  mode: TapNpayPaymentMode;
  dateFormat?: string;
  currencyCode?: string;
}

export interface TapNpayBookingDetails {
  amountDue: number;
  accommodationTotal: number | string;
  extraTotal: number | string;
  paymentsTotal: number | string;
  clientFullName: string | undefined;
  fromDate: string;
  toDate: string;
}

export interface TapNpayAuth { bbid: number, loginkey: string }

export type TapNpaySource = 'bridgeit' | 'nightsbridge';

export const TapNpaySources: {[key in 'BRIDGEIT' | 'NIGHTSBRIDGE']: TapNpaySource} = {
  BRIDGEIT: 'bridgeit',
  NIGHTSBRIDGE: 'nightsbridge'
}

export type TapNpayQueryMessage = 'PaymentTapRQ' | 'PaymentTapEnabledRQ' | 'DeviceManagementRQ' | 'RefundTapRQ' | 'DeviceManagementR' | 'PaymentTapFeatureStatusRQ' | 'PaymentTapScanRQ';

export type TapNpayPaymentMode = 'payment' | 'refund';

export const TapNpayPaymentModes: {[key in 'PAYMENT' | 'REFUND']: TapNpayPaymentMode} = {
  PAYMENT: 'payment',
  REFUND: 'refund'
}

// ENABLED

export class IsPaymentTapEnabledNBresponse {
  paymenttapenabled = false
}

export interface IsPaymentTapBTPayload {
  messagename: TapNpayQueryMessage;
  credentials: { loginkey: string };
  bbid: number;
  bookingId?: number;
}
export interface PaymentTapQrPayload {
  messagename: TapNpayQueryMessage;
  credentials: { loginkey: string };
  bbid?: number;
  bookingId?: number;
  amount: number
}

export interface PaymentTapQrResponse {

}

export class IsPaymentTapBTresponse {
  data: {paymentTapEnabled: boolean} = {paymentTapEnabled: false};
  error: any;
  success = false
}

export type IsPaymentTapResponse = IsPaymentTapEnabledNBresponse | IsPaymentTapBTresponse;

// REVERSAL ALLOWED

export class IsReversalAllowedNBresponse {
  paymentid = 0;
  status: TapNpayPaymentReversalStatus = 'NOT_POSITIVE_REVERSAL_AMOUNT'
}

export const PAYMENT_REVERSAL_STATUSES: {[key in TapNpayPaymentReversalStatus]: TapNpayPaymentReversalStatus} = {
  PREVIOUS_REVERSAL: 'PREVIOUS_REVERSAL',
  NOT_POSITIVE_REVERSAL_AMOUNT: 'NOT_POSITIVE_REVERSAL_AMOUNT',
  PAID_OVER: 'PAID_OVER',
  UNSUCCESSFUL: 'UNSUCCESSFUL',
  REVERSAL_ALLOWED: 'REVERSAL_ALLOWED'
}

// DEVICES

export type TapNpayDeviceStatus = string | 'DEVICE_STATUS_ENABLED';

export const TapNpayDeviceStatuses: {[key in TapNpayDeviceStatus]: TapNpayDeviceStatus} = {
  DEVICE_STATUS_ENABLED: 'DEVICE_STATUS_ENABLED'
};

export type TapNpayDeviceBT = {
  deviceId: string,
  state: TapNpayDeviceStatus,
  manufacturer: string,
  model: string,
  nickname: string,
  osName?: string,
  osPlatform?: string,
  osVersionName?: string,
};

export type TapNpayDeviceNB = {
  deviceid: string,
  state: TapNpayDeviceStatus,
  manufacturer: string,
  model: string,
  nickname: string,
  osname: string,
  osplatform: string,
  osversionname: string
};

export class TapNpayDevicesNBresponse {
  devices: TapNpayDeviceNB[] = []
}


export class TapNpayDevicesBTresponse {
  data: {devices: TapNpayDeviceBT[]} = {devices: []};
  success = false;
  error = null
}
export class PaymentTapQrBTresponse {
  data: {paymentId: string, qrCode: string, qrCodeUrl } = {qrCodeUrl: '', qrCode: '', paymentId: ''};
  success = false;
  error = null
}


export type TapNpayDevicesResponse = TapNpayDevicesNBresponse | TapNpayDevicesBTresponse;

// TRANSACTIONS

export interface TapNpayTransactionRequestNBpayload {
  bbid: number;
  bookingid: number;
  amount: number;
  source: TapNpaySource;
  deviceid: string;
  initialpaymentid?: string;
}

export interface TapNpayTransactionBTpayload {
  bbid: number;
  bookingId: number;
  deviceId: string;
  amount: number;
  messagename: TapNpayQueryMessage;
  credentials: { loginkey: string };
  source: TapNpaySource;
  initialpaymentid?: number;
}

export type PaymentTapFeatureStatus = {
  paymentTapFeatureEnabled: boolean;
  paymentTapStatusIsActive: boolean;
  paymentTapPropertyEnabled: boolean;
  paymentTapProvider: string;
}

export type PaymentTapFeatureStatusRS = {
  data: PaymentTapFeatureStatus;
  success: boolean;
}

export class TapNpayProcessPaymentNBresponse {
  paymentid = '';
  status = false;
  description = ''
}

export const tapNpayModalOptions: NgbModalOptions = {
  backdrop: "static",
  keyboard: false,
  centered: true,
  fullscreen: "sm",
  modalDialogClass: "tap-n-pay-modal-decorator",
  windowClass: "modal-window-centered"
};

export const tapNpayModalMessageOptions: NgbModalOptions = {
  backdrop: "static",
  keyboard: false,
  centered: true,
  fullscreen: "sm",
  modalDialogClass: "tap-n-pay-modal-message-decorator",
  windowClass: "modal-window-centered"
};

export class TapNpayProcessPaymentBTresponse {
  data: {
    paymentId: string;
    status: boolean,
    description: string
  } =  { paymentId: '', status: false, description: '' };
}

export type TapNpayProcessPaymentResponse = TapNpayProcessPaymentNBresponse | TapNpayProcessPaymentBTresponse;

export interface TapNpayProcessTransaction {hash: string, timeOutTimer$?: Subscription, status: TapNpayStatus}

// STATUS

export interface TapNpayStatusPayloadBT {
  bbId: number;
  source?: TapNpaySource;
  messagename?: string;
  credentials?: {loginkey: string};
  paymentId: string;
  hash: string;
  timeOutTimer$?: Subscription;
}

export interface TapNpayStatusPayloadNB {
  bbId: number;
  source?: TapNpaySource;
  paymentId: string;
  hash: string;
  timeOutTimer$?: Subscription;
}

export interface TapNpayStatusResponseBT { data: { status: TapNpayStatus }; success: boolean; }

export interface TapNpayStatusResponseNB { status: TapNpayStatus }

export type TapNpayStatus =  'APPROVED' | 'DECLINED' | 'CANCELLED' | 'FAILED' | 'VOIDED';

export const TapNpayStatuses: {[key in TapNpayStatus]: TapNpayStatus} = {
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
  CANCELLED: 'CANCELLED',
  FAILED: 'FAILED',
  VOIDED: 'VOIDED'
};

export type TapNpayPaymentReversalStatus = 'PREVIOUS_REVERSAL' | 'NOT_POSITIVE_REVERSAL_AMOUNT' | 'PAID_OVER' | 'UNSUCCESSFUL' | 'REVERSAL_ALLOWED';

export const PAYMENT_REVERSAL_STATUS: {[key in TapNpayPaymentReversalStatus]: TapNpayPaymentReversalStatus} = {
  PREVIOUS_REVERSAL: 'PREVIOUS_REVERSAL',
  NOT_POSITIVE_REVERSAL_AMOUNT: 'NOT_POSITIVE_REVERSAL_AMOUNT',
  PAID_OVER: 'PAID_OVER',
  UNSUCCESSFUL: 'UNSUCCESSFUL',
  REVERSAL_ALLOWED: 'REVERSAL_ALLOWED'
}

// Websocket Messages

export interface TapNpayWebsocketMessageBT {
  paymentId: string,
  deviceId: string,
  bookingId: string,
  transactionId: string,
  transactionDate: string,
  status: string,
  amount: number,
  action: string
}

export interface TapNpayWebsocketMessageNB {
  paymentId: string,
  deviceId: string,
  bookingId: string,
  transactionId: string,
  transactionDate: string,
  status: string,
  amount: number,
  action: string
}

export type TapNpayWebsocketMessage = TapNpayWebsocketMessageNB | TapNpayWebsocketMessageBT;

// HTTP

export interface TapNpayHttpClient {
  [httpMethod: string]: <T>(url: string | string[], params?: object) => Observable<T>;
}

export const TAP_N_PAY_HTTP_TOKEN = new InjectionToken('TAP_N_PAY_HTTP');




