import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from 'src/app/service/translate.service';
import { AccountService } from 'src/app/service/account.service';
import { DatelibraryService } from 'src/app/service/datelibrary.service';
import { ConfirmationService } from 'src/app/service/confirmation.service';
import { PageRouteService } from 'src/app/service/page-route.service';
import { Constants } from 'src/app/service/models/enum/constants.enum';
import { PageRouteDataService } from 'src/app/service/page-route-data.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-client-account',
  templateUrl: './client-account.component.html',
  styleUrls: ['./client-account.component.scss']
})
export class ClientAccountComponent implements OnInit {
  @Input() options;

  clientDescription: any;
  private pageData: any;
  private returnData: any;
  private listAccounts: any[];
  client: {[key: string]: any} = {};
  newAccountBtnVisibility: boolean;
  moveAccountBtnVisibility: boolean;
  private bbaccountid: any;

  constructor(
    private translateService: TranslateService,
    private accountService: AccountService,
    private datelibraryService: DatelibraryService,
    private confirmationService: ConfirmationService,
    private pageRouteService: PageRouteService,
    private pageRouteDataService: PageRouteDataService,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.pageData = this.pageRouteDataService.getData();
    this.returnData = this.pageRouteDataService.getReturnData();
    this.listAccounts = []; // Holds on to this client's account list.

    this.getLatestPageState();

    if (this.options) {
      this.retrieveDataForProcessing();
    }
   }

  close(returnData?) {
    this.pageRouteService.back(returnData);
    if(this.activeModal) {
      this.activeModal.close();
    }
  }

  addToNewAccount() {
    this.confirmationService.show({
      title: 'confirmation',
      text: this.translateService.translate('clientAccount', 'clientAccountAdd'),
      buttons: [{
        text: 'no',
        class: 'btn__warning',
        callback: () => {}
      }, {
        text: 'yes',
        class: 'btn__green',
        callback: () => {
          this.createNewAccountAndUpdateItem(this.client.clientid, this.options.type, this.options.id,
              (data) => {
                  this.close(data);
          });
        }
      }]
    });
  }

  changeClient() {
    // Switch to the Clients screen to pick a new client
    this.pageRouteService.navigate('client-account', {
      ...this.getNavigationState(),
      onClose: 'changeClient',
    }, ['/clients/search'], {
      mode: 2, // Applices to changing a view
    });
  }

  moveToAccount() {
    this.formatAccountData(this.listAccounts);
    this.pageRouteService.navigate('client-account', {
      ...this.getNavigationState(),
      onClose: 'this.processAccountForUpdate',
    }, ['/client-account/list'], {
      client: this.client,
      accounts: this.listAccounts,
      itemCount: this.options.itemCount
    });
  }

  // This function creates a new account and updates the item.
  // It is called under several scenarios
  // - Adding of a new client
  // - Selecting a client with no account

  createNewAccountAndUpdateItem(clientID, itemType, itemID, callback) {
    this.addAccount(clientID, (data) => {
      this.updateAccount(itemType, itemID, data.account.accountid, (data2) => {
        callback.bind(this)(data2);
      });
    });
  }

  addAccount(clientID, callback) {
    this.accountService.addAccount(clientID).subscribe((response) => {
      callback.bind(this)(response.data);
    });
  }

  updateAccount(itemType, itemID, accountID, callback) {
    this.accountService.updateClientAccount(itemType, itemID, accountID).subscribe((response) => {
      callback.bind(this)(response.data);
    });
  }

  getClientAccounts(clientID, callback) {
    this.accountService.clientAccounts(Constants.ACCOUNT_OPEN, clientID).subscribe((response) => {
      callback.bind(this)(response.data);
    });
  }

  formatAccountData(accounts) {
    accounts.forEach((account) => {
      account.description = this.getLineItemDescription(account.details);
    });
  }

  getLineItemDescription(accountInfo) {
    let description = '';
    if (accountInfo.error) {
      if (accountInfo.isbooking) {
        description = this.translateService.translateWithVariables('clientAccount', 'lineitemBookingError',
            {bookingid: accountInfo.bbbookingid});
      } else {
        description = this.translateService.translateWithVariables(
            'clientAccount', 'lineitemExtraError', {extraid: accountInfo.extraid});
      }
    } else if (accountInfo.isbooking) {
      description = this.translateService.translateWithVariables(
          'clientAccount', 'lineitemBooking',
          {bookingid: accountInfo.bbbookingid,
            fromdate: this.datelibraryService.getLocalDate(accountInfo.fromdate),
            todate: this.datelibraryService.getLocalDate(accountInfo.todate),
            clientname: accountInfo.clientname } );
    } else {
      description = this.translateService.translateWithVariables(
          'clientAccount', 'lineitemExtra',
          { extradate: this.datelibraryService.getLocalDate(accountInfo.extradate),
                 qty: accountInfo.qty, stockname: accountInfo.stockname});
    }
    return description;
  }

  getNavigationState() {
    return this.options;
  }

  processAccountForUpdate(newAccount) {
      this.updateAccount(this.options.type, this.options.id, newAccount.accountid, () => {
        this.close(newAccount);
      });
    }

  getClientAccount(itemType, itemID, accountID, callback) {
    this.accountService.clientAccount(itemType, itemID, accountID).subscribe((response) => {
      callback(response.data);
    });
  }

  getLatestPageState() {
    if (this.options) {
      return;
    }
    if (!this.pageData) {
      return;
    }
    this.options = this.pageData;
  }

  private retrieveDataForProcessing() {
    this.getClientAccount(this.options.type, this.options.id, this.options.accountid,
        (client) => {
      this.bbaccountid = client.bbaccountid;;
      this.clientDescription = this.getLineItemDescription(client.description);
      this.client = client.client;
      this.client.clientname = this.accountService.getClientFullName(this.client);
      this.newAccountBtnVisibility = this.options.showNewAccount;

      this.getClientAccounts(this.client.clientid, (accounts) => {
        for (let i = 0; i < accounts.accounts.length; i++) {
          if (!(accounts.accounts[i].bbaccountid === this.bbaccountid)) {
                this.listAccounts.push(accounts.accounts[i]);
          }
        }
        this.newAccountBtnVisibility = this.options.showNewAccount;
        this.moveAccountBtnVisibility = this.listAccounts.length > 0;

        this.processDataReceived();
      });
    });
  }
  processDataReceived() {
    if (!this.returnData || !this.pageData?.onClose) {
      return;
    }

    const changeClient = (client) => {
      // If we've chosen the same client, then don't do anything.
      if (client.clientid === this.client.clientid) { return; }

      // If a new client has been chosen then we check if that client has any existing accounts.
      // If not, then we can proceed to creating a new account and allocating it.
      this.getClientAccounts(client.clientid, (accounts) => {
        if (accounts.accounts.length === 0) { // Selected client has no account on it. Create and continue.
          this.createNewAccountAndUpdateItem(client.clientid, this.options.type, this.options.id, (account) => {
            this.close(account);
          });
        } else {
          // The client selected has 1 or more accounts on it.
          // Let the user choose one.

          // We adjust the account list data to add our line item description
          // We do it here so as not to reproduce the code in ClientAccountList
          // See moveToAccount as well
          this.formatAccountData(accounts.accounts);
          // accounts.accounts.filter(account.bookingid =>);
          this.pageRouteService.navigate('client-account', {
            ...this.getNavigationState(),
            onClose: 'this.processAccountForUpdate',
          }, ['/client-account/list'], {
            client: client,
            accounts: accounts.accounts,
          });
        }
      });
    };

    // find out from which function we were redirected
    let onClose;
    switch (this.pageData.onClose) {
      case 'changeClient':
        onClose = changeClient;
        break;
      case 'this.processAccountForUpdate':
        onClose = this.processAccountForUpdate;
        break;
    }

    if (onClose) {
      onClose.bind(this)(this.returnData);
    }
  }
}
