<app-tap-n-pay-modal-container>
  <div>
    <p class="fs-16 fw-bold text-center"> Transaction in progress... </p>

    <p *ngIf="secondsLeft > 0" class="tnp-blue text-align-center text-center">
      {{secondsToMinutes(secondsLeft)}}
    </p>
  </div>

  <div>
    <div class="d-flex justify-content-center" *ngIf="qrCode">
      <img [src]="qrCode | safe: 'resourceUrl'" />
    </div>
    <p class="text-center fw-semi-bold fs-14"> Scan QR to Launch </p>
  </div>

  <cui-info-bar customClass="no-toggle">
    <div description class="d-flex flex-column gap-1">
      <div class="d-flex gap-3">
        <div class="w-20">
          <svg class="ic ic-22 medium-icon align-self-center color-secondary-text">
            <use href="assets/images/sprite.svg#light-star-icon"/>
          </svg>
        </div>
        <div class="d-flex flex-column gap-2">
          <div class="d-flex flex-column h-100 justify-content-end">
            <div class="fw-bold text-primary fs-14"> Note </div>
          </div>
        </div>
      </div>
      <p class="fs-14">
        Use your camera to scan & launch the NightsBridge Tap application
      </p>
    </div>
  </cui-info-bar>

</app-tap-n-pay-modal-container>

<div class="d-flex justify-content-end modal-footer border-top w-100">
  <cui-button (onClick)="activeModal.close()" label="Cancel" name="cancel" classes='tap-n-pay-btn-cancel tap-n-pay-btn-action-override' mode="light"></cui-button>
</div>
