import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {TapNpayComponent} from "./tap-n-pay.component";
import {TapNpayModalAmountComponent} from "./modals/tap-n-pay-modal-amount/tap-n-pay-modal-amount.component";
import {TapNpayModalContainerComponent} from "./modals/tap-n-pay-modal-container/tap-n-pay-modal-container.component";
import {TapNpayModalSpinnerComponent} from "./modals/tap-n-pay-modal-spinner/tap-n-pay-modal-spinner.component";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TapNpayModalDevicesComponent} from "./modals/tap-n-pay-modal-devices/tap-n-pay-modal-devices.component";
import {TapNpayModalApprovedComponent} from "./modals/tap-n-pay-modal-approved/tap-n-pay-modal-approved.component";
import {TapNpayModalDeclinedComponent} from "./modals/tap-n-pay-modal-declined/tap-n-pay-modal-declined.component";
import {TapNPayModalMessageComponent} from "./modals/tap-n-pay-modal-message/tap-n-pay-modal-message.component";
import {CUICarouselPaginatorComponent} from "@nightsbridge/common-ui/carousel-paginator";
import {CUIButtonComponent} from "@nightsbridge/common-ui/button";
import {CUIInputComponent} from "@nightsbridge/common-ui/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TapNPayModalQrComponent} from '@shared/tap-n-pay/modals/tap-n-pay-modal-qr/tap-n-pay-modal-qr.component';
import {CUIinfoBarComponent} from '@nightsbridge/common-ui/info-bar';
import {SharedModule} from '../../shared.module';

@NgModule({
  declarations: [
    TapNpayComponent,
    TapNpayModalAmountComponent,
    TapNpayModalContainerComponent,
    TapNpayModalSpinnerComponent,
    TapNpayModalSpinnerComponent,
    TapNpayModalDevicesComponent,
    TapNpayModalApprovedComponent,
    TapNpayModalDeclinedComponent,
    TapNPayModalMessageComponent,
    TapNPayModalQrComponent
  ],
  imports: [CommonModule, CUICarouselPaginatorComponent, CUIButtonComponent, CUIInputComponent, FormsModule, ReactiveFormsModule, CUIinfoBarComponent, SharedModule],
  exports: [TapNpayComponent],
  providers: [NgbActiveModal],
})
export class TapNpayModule {}
