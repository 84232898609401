<div class="d-flex flex-lg-row flex-md-column flex-sm-column gap-1">
  <div class="d-flex flex-row gap-1">
    <x-cui-date-selector
      [(ngModel)]="startDate"
      [viewMode]="2"
      [dateText]="'dateRange' | translate:( labelMode === 1 ? 'startDate' : 'arrival')"
      [readOnly]="true"
      [iconColor]="'color-mono-primary-text'">
    </x-cui-date-selector>
    <x-cui-date-selector 
      [(ngModel)]="endDate"
      [viewMode]="2"
      [dateText]="'dateRange' | translate:( labelMode === 1 ? 'endDate' : 'departure')"
      [readOnly]="true"
      [iconColor]="'color-mono-primary-text'">
    </x-cui-date-selector>
  </div>  
  <div class="d-flex max-w-75">
    <cui-input
      type="number"
      min="1"
      max="365"
      [label]="'dateRange' | translate:(labelMode === 1 ? 'days' : 'nights')"
      [(ngModel)]="duration"
      sizing="md"
      (input)="onInputChange($event)">
    </cui-input>
  </div>
</div>