import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {NbLibraryService} from "../../../service/nb-library.service";
import {DatelibraryService} from "../../../service/datelibrary.service";
import {BookingSummary} from "../../../service/models/BookingSummary";
import {FormGroup} from "@angular/forms";
import {ReportBookingStatus} from "../../../service/models/enum/booking-status.enum";
import {TapNpayService} from "@shared/tap-n-pay/tap-n-pay.service";
import {CredentialService} from "../../../service/credential.service";
import {environment} from '../../../../environments/environment';
import {HttpParams} from '@angular/common/http';
import {HttpInterceptorService} from '../../../service/http-interceptor.service';
import {Subject, takeUntil} from 'rxjs';
import {BookingComReportResponse} from 'src/app/service/models/CancelBookingCom';

const TAP_N_PAY_SETUP_URL = environment.nbDashboard + '/integrations/tap-n-pay';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent implements OnDestroy {
  private destroy$ = new Subject<void>();

  @Output() viewAccountClicked = new EventEmitter<void>();
  @Output() viewRatesClicked = new EventEmitter<void>();
  @Output() checkoutClicked = new EventEmitter<void>();
  @Output() invalidccClicked = new EventEmitter<void>();
  @Output() noShowClicked = new EventEmitter<void>();
  @Output() cancelBookingComClicked = new EventEmitter<void>();
  @Output() calculateDepositClicked = new EventEmitter<void>();
  @Output() updateDeposit = new EventEmitter<void>();
  @Output() viewStatusAndHistoryClicked = new EventEmitter<void>();

  @Input() bookingSummaryObject: BookingSummary;
  @Input() bookingSummaryForm: FormGroup;
  @Input() reportInvalidCCBookingStatus: ReportBookingStatus;
  @Input() reportNoShowStatus: ReportBookingStatus;
  @Input() invalidCCReported: boolean;
  @Input() noShowReported: boolean;
  @Input() typeReported: any;
  @Input() reportCancelBookingCom!: { showCancelButton: boolean; isCancellationReported: boolean };

  ReportBookingStatus = ReportBookingStatus;

  setupPageUrl = TAP_N_PAY_SETUP_URL;
  tapNpayFeatureStatus$ = this.tapNpayService.tapNpayFeatureStatus$;

  constructor(
      private nbLibraryService: NbLibraryService,
      private dateLibraryService: DatelibraryService,
      private credentialService: CredentialService,
      private tapNpayService: TapNpayService,
      private httpInterceptor: HttpInterceptorService
  ) {
    this.setRedirectToPaymentTapSetupURL();
  }

  viewAccount() {
    this.viewAccountClicked.emit();
  }

  viewRates() {
    this.viewRatesClicked.emit();
  }

  checkout() {
    this.checkoutClicked.emit();
  }

  invalidCC() {
    if (!this.invalidCCReported) {
      this.invalidccClicked.emit();
    }
  }

  cancelBookingCom() {
    if(!this.reportCancelBookingCom?.isCancellationReported) {
      this.cancelBookingComClicked.emit();
    }
  }

  noShow() {
    if (!this.noShowReported) {
      this.noShowClicked.emit();
    }
  }

  calculateDeposit() {
    this.calculateDepositClicked.emit();
  }

  viewStatusAndHistory() {
    this.viewStatusAndHistoryClicked.emit();
  }

  formatCurrency(value: string | number) {
    return this.nbLibraryService.formatCurrency(value);
  }

  getLocalDateTime(date: string) {
    return this.dateLibraryService.getLocalDateTime(date);
  }

  getCurrency() {
    return this.credentialService.getCurrency;
  }

  setRedirectToPaymentTapSetupURL() {
    const data = {
      messagename: 'PaymentTapSignUpRQ',
      credentials: {loginkey: this.credentialService.getLoginKey},
      bbid: this.credentialService.getBBID,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    this.httpInterceptor.postRequest(environment.apiUrl + '/bridgeitapi', body).pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.setupPageUrl = response.data.redirectUrl;
    });
  }

  ngOnDestroy() {
      this.destroy$.next();
      this.destroy$.complete();
  }
}
