import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from 'src/app/service/translate.service';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss']
})
export class EmailConfirmationComponent {
  @Input() options;
  email: string = '';
  bcc: boolean = false;
  emailError: string = '';

  constructor(public activeModal: NgbActiveModal, private translateService: TranslateService) { }

  ngOnInit() {
    if (this.options?.email) {
      this.email = this.options.email;
    }
  }

  close() {
    this.activeModal.dismiss();
  }

  getTemplateMessage(): string {
    const messageKeyMap = {
      'invoice' : 'invoiceEmailedTo',
      'proforma':'proformaEmailedTo',
      'creditnote':'creditNoteEmailedTo',
      'guest-statement':'guestStatementEmailedTo'

    };

    const messageKey = messageKeyMap[this.options.template] || 'documentEmailedTo';
    return this.translateService.translate('accountSummary', messageKey);
  }

  validateEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email.trim());
  }

  validateEmails(emailString: string): {isValid: boolean; isSingleEmail: boolean} {
    if (!emailString.trim()) {
      return {isValid: false, isSingleEmail: true};
    }

    const isSingleEmail = !emailString.includes(',') && !emailString.includes(';');

    if (isSingleEmail) {
      return {
        isValid: this.validateEmail(emailString),
        isSingleEmail: true
      };
    }

    const emails = emailString
      .split(/[,;]/)
      .map(email => email.trim())
      .filter(email => email.length > 0);

    const allValid = emails.every(email => this.validateEmail(email));

    return {
      isValid: allValid,
      isSingleEmail: false
    };
  }

  buttonClick(button) {
    this.emailError = '';

    if (!this.email.trim()) {
      this.emailError = 'Email address is required';
      return;
    }

    const validationResult = this.validateEmails(this.email);

    if (!validationResult.isValid) {
      this.emailError = validationResult.isSingleEmail
        ? 'Please enter a valid email address'
        : 'Please enter valid email addresses';
      return;
    }

    const cleanedEmails = this.email
      .split(/[,;]/)
      .map(email => email.trim())
      .filter(email => email.length > 0)
      .join(', ');

    button.callback({
      email: cleanedEmails || this.email.trim(),
      bcc: this.bcc
    });

    this.activeModal.close();
  }
}