import {Component, Input} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TapNpayStatus, TapNpayStatuses} from '../../tap-n-pay.class';
import {TapNpayService} from '../../tap-n-pay.service';


@Component({
  selector: 'app-tap-n-pay-modal-declined',
  templateUrl: './tap-n-pay-modal-declined.component.html',
  styleUrls: ['./tap-n-pay-modal-declined.component.scss']
})
export class TapNpayModalDeclinedComponent {

  protected readonly TapNpayStatuses = TapNpayStatuses;

  @Input() mode: TapNpayStatus = 'DECLINED';
  @Input() isRetryEnabled = true;

  constructor(
    public activeModal: NgbActiveModal,
    private tapNpayService: TapNpayService
  ) {}

 retry() {
   this.activeModal.dismiss();
   this.tapNpayService.processPayment(this.activeModal);
 }

 close() {
  this.activeModal.close();
 }

}
