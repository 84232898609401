<div class="model-content bookingSummaryContainer" xmlns="http://www.w3.org/1999/html">
  <div class="modal-header d-flex justify-content-between ">
    <span></span>
    <label class="typography__h3">
      {{'bookingSummary' | translate: 'bookingSummary'}}
    </label>
    <div class="close-modal" (click)="exit()">
        <span class="helper"></span>
      <img src="assets/images/close.svg" />
    </div>
  </div>
  <form [formGroup]="bookingSummaryForm">
    <div class="d-flex align-items-stretch">
      <!-- left card -->
      <div class="modal-body col-5 col-xxl-4 d-flex flex-column vertical-gutter">
        <div class="d-flex align-items-center horizontal-gutter">
           <span>
              <span class="subsection-heading">{{"bookingSummary" | translate:'nbid'}}</span>
              <span class="typography__p">{{bookingSummaryObj.booking?.bookingid}}</span>
           </span>
          <span>
              <span class="subsection-heading">{{"bookingSummary" | translate:'bookingid'}}</span>
              <span class="typography__p">{{bookingSummaryObj.booking?.bbbookingid}}</span>
           </span>
        </div>

        <ng-container *ngIf="!bookingSummaryObj.textAreaFocus">
          <div class="d-flex flex-wrap gap-2">
             <div class="status-container">
              <label class="inputField__label">
                {{'bookingSummary'| translate:'status'}}
              </label>
              <ng-select [clearable]="false" formControlName='status'
                         (change)="statusChanged($event)" [searchable]="false"
                         [ngbTooltip]="statuscodeMap[bookingSummaryForm.value.status]"
                         tooltipClass="tooltip__container"
                         class="status m-right-12"
              >
                 <ng-option *ngFor="let status of statuscodes" [value]="status.status" class="typography__p">
                  {{ status.description }}
                </ng-option>
              </ng-select>
            </div>
            <div *ngIf="bookingSummaryObj.booking?.status === 'P' || bookingSummaryObj.booking?.status === 'R'" class="flex-1">
              <div class="col-md-12">
                <label class=" inputField__label">
                  {{'bookingSummary'| translate:'until'}}
                </label>

              </div>
              <div class="d-flex horizontal-gutter">
                <div>
                  <div class="date-picker min-width-8">
                    <input type="text"
                           class="typography__p"
                           #until="bsDatepicker"
                           bsDatepicker
                           readonly
                           appDatepicker
                           formControlName="statusdate"/>
                    <div class="input-group-prepend">
                      <button class="input-group-text" id="until" (click)="until.toggle()">
                        <img src="assets/images/icons/ic_date_range.svg">
                      </button>
                    </div>
                  </div>
                </div>
                <div class="time-picker">
                  <timepicker formControlName="statustime" [showSpinners]="false" [showMeridian]="false"
                              ngbTooltip="{{ 'bookingSummary'| translate: 'hourMin'}}"
                              tooltipClass="tooltip__container"></timepicker>
                </div>
              </div>
            </div>
          </div>
          <div>
            <app-date-range [range]="dateRange" [labelMode]="2" (onChange)="dateRangeChanged($event)"></app-date-range>
          </div>

          <div class="d-flex align-items-end horizontal-gutter-medium">

            <div class="flex-grow-1">
              <label class=" inputField__label">
                {{'bookingSummary'| translate:'madeBy'}}
              </label>
              <input type="text" [value]="bookingSummaryObj.madebyclient?.fullname" readonly
                     ngbTooltip="{{bookingSummaryObj.madebyclient?.notes}}" tooltipClass="tooltip__container"/>
            </div>

            <div>
              <button class="btn__icon btn__regular-icon--active-green"
                      (click)="editClient(bookingSummaryObj.madebyclient.clientid)"
                      ngbTooltip="{{'bookingSummary' | translate:'clientEdit'}}"
                      tooltipClass="tooltip__container">
                <svg class="ic ic_person">
                  <use href="assets/images/icons/sprite.svg#ic_person" />
                </svg>
              </button>
            </div>

            <div>
              <button class="btn__icon btn__regular-icon--active-blue" (click)="changeMadeByClient()"
                      ngbTooltip="{{'bookingSummary' | translate:'clientChange'}}"
                      tooltipClass="tooltip__container">
                <svg class="ic ic_add_person">
                  <use href="assets/images/icons/sprite.svg#ic_add_person" />
                </svg>
              </button>
            </div>

            <div>
               <button class="btn__icon btn__regular-icon--active-blue"
                       [disabled]="!bookingSummaryObj.madebyclient?.email"
                      ngbTooltip="{{'bookingSummary' | translate:'clientEmail'}}" tooltipClass="tooltip__container">
                <a (click)="sendEmail(bookingSummaryObj.madebyclient?.email)" href="mailto:{{bookingSummaryObj.madebyclient?.email}}">
                  <svg class="ic ic_mail">
                    <use href="assets/images/icons/sprite.svg#ic_mail" />
                  </svg>
                </a>
              </button>
            </div>

          </div>

          <div class="d-flex align-items-end horizontal-gutter">
            <div class="flex-grow-1">
              <label class=" inputField__label">
                {{'bookingSummary'|translate:'source'}}
              </label>
              <input [(ngModel)]="bookingSummaryObj.booking.source"
                     [ngModelOptions]="{standalone: true}" readonly/>
            </div>
            <div *ngIf="bookingSummaryObj.showsourcereplace">
              <button class="btn__icon btn__secondary-icon" (click)="searchSources()"
                      ngbTooltip="{{'bookingSummary' | translate:'sourceSearch'}}"
                      tooltipClass="tooltip__container">
                <svg class="ic ic_search">
                  <use href="assets/images/icons/sprite.svg#ic_search" />
                </svg>
              </button>
            </div>

            <div class="commission__fixed_width">
              <label class=" inputField__label">
                {{'bookingSummary'|translate:'commission'}}
              </label>
              <input (blur)="parseCommission()" class="commission-input currencyTextAlign" formControlName='agentcomm' appPercent />
            </div>
          </div>

          <div>
              <label class=" inputField__label">
                {{'bookingSummary'|translate:'reference'}}
              </label>
              <input
                formControlName="reference"
                maxlength="30"
              />
          </div>
        </ng-container>

        <div class="d-flex flex-column flex-grow-1">
          <div class="d-flex justify-content-between align-items-end">
            <label class=" inputField__label">
              {{'bookingSummary'|translate:'notes'}}
            </label>

            <img class="action-icon small"
                 (click)="closeNotes()"
                 *ngIf="bookingSummaryObj.textAreaFocus"
                 src="../../../assets/images/icons/dropdown_btn_down.svg"
                 ngbTooltip="{{'bookingSummary' | translate:'closeNotes'}}"
                 tooltipClass="tooltip__container"/>
            <img class="action-icon small"
                 (click)="openNotes(true)"
                 *ngIf="!bookingSummaryObj.textAreaFocus"
                 src="../../../assets/images/icons/dropdown_btn_up.svg"
                 ngbTooltip="{{'bookingSummary' | translate:'openNotes'}}"
                 tooltipClass="tooltip__container"/>
          </div>
          <textarea class="flex-grow-1 typography__p" name="bookingnotes" formControlName='notes'
                    (focus)="openNotes(false)"
                    (blur)="closeNotes()"></textarea>
        </div>
      </div>

      <!-- middle card -->
      <div class="modal-body col-4 vertical-gutter">

        <div class="">
          <div class="d-flex justify-content-between align-items-center">
               <span class="section-heading roomname__fixed_width">
                  {{ (bookingSummaryObj.guests && (bookingSummaryObj.selectedguestIndex !== undefined) && bookingSummaryObj.guests[bookingSummaryObj.selectedguestIndex]) ? bookingSummaryObj.guests[bookingSummaryObj.selectedguestIndex].roomname : ''}}
               </span>

            <div class="d-flex align-items-center" *ngIf="bookingSummaryObj.guests?.length > 1">
              <div class="subsection-heading">
                {{bookingSummaryObj.selectedguestIndex + 1}}/{{bookingSummaryObj.guests?.length}}
              </div>

              <div ngbDropdown
                   class="d-inline-block"
                   placement="bottom-right"
              >
                <img class="action-icon medium tooltip__container"
                     ngbDropdownToggle
                     src="../../../assets/images/icons/dropdown_btn_down.svg"
                     ngbTooltip="{{'bookingSummary' | translate:'viewRooms'}}" tooltipClass="tooltip__container"/>
                <div ngbDropdownMenu class="roomDropdown">
                  <div class="roomDropdown__search">
                    <input type="text" [placeholder]="'bookingSummary' | translate : 'search'" [(ngModel)]="roomFilter"
                           [ngModelOptions]="{standalone: true}"/>
                  </div>
                  <button
                    ngbDropdownItem
                    *ngFor="let guest of bookingSummaryObj.guests | filter : roomFilter : 'roomname'"
                    (click)="changeRoomView(guest)"
                    [ngbTooltip]="guest?.roomname"
                    tooltipClass="tooltip__container">
                    {{guest?.roomname}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="">
          <label class=" inputField__label">{{"bookingSummary" | translate:'roomOptions'}}</label>
          <div class="d-flex align-items-center horizontal-gutter">
            <button type="button" class="btn btn__sm btn__secondary_action" (click)="addRoom()">
              {{"bookingSummary" | translate:'add'}}
            </button>
            <button type="button" class="btn btn__sm btn__secondary_action" (click)="changeRoom()"
                    *ngIf="!bookingSummaryObj.changeRoomDisabled">
              {{"bookingSummary" | translate:'change'}}
            </button>
            <button type="button" class="btn btn__sm btn__secondary_action" (click)="splitRoom()"
                    *ngIf="bookingSummaryObj.guests?.length > 1">
              {{"bookingSummary" | translate:'split'}}
            </button>
            <button type="button" class="btn btn__sm btn__secondary_action" (click)="cancelRoom()"
                    *ngIf="bookingSummaryObj.guests?.length > 1">
              {{"bookingSummary" | translate:'cancel'}}
            </button>
          </div>
        </div>
        <div>
          <label class=" inputField__label">{{"bookingSummary" | translate:'rates'}}</label>
          <ng-select [clearable]="false" formControlName='bbrateid' (change)="toggleRates()" [searchable]="false">
            <ng-option *ngFor="let option of bookingSummaryObj.guest?.arroomrates" [value]="option.bbrateid"
                       class="typography__p">
              {{ option.description }}
            </ng-option>
          </ng-select>
        </div>
        <div class="d-flex horizontal-gutter">
          <div class="">
            <label class=" inputField__label">
              {{'bookingSummary'| translate:'adults'}}
            </label>
            <input type="text" formControlName='noadults' (change)="toggleRates()"/>
          </div>
          <div class="">
            <label class=" inputField__label">
              {{'bookingSummary'| translate:'child'}}
              ({{options.childDisplay1}})
            </label>
            <input type="text" formControlName='child1' (change)="toggleRates()"/>
          </div>
          <div class="" *ngIf="bookingSummaryObj.establishment.childage2 > 0">
            <label class=" inputField__label">
              {{'bookingSummary'| translate:'child'}}
              ({{options.childDisplay2}})
            </label>
            <input type="text" formControlName='child2' (change)="toggleRates()"/>
          </div>
        </div>

        <div class="">
          <label class=" inputField__label">
            {{'bookingSummary'| translate:'guest'}}
          </label>
          <div class="d-flex horizontal-gutter horizontal-gutter-medium">

            <input type="text" [value]="bookingSummaryObj.guest?.clientfullname" readonly
                   ngbTooltip="{{bookingSummaryObj.guest?.clientnotes}}" tooltipClass="tooltip__container"/>

            <div>
              <button class="btn__icon btn__regular-icon--active-green" (click)="editClient(bookingSummaryObj.guest.clientid)"
                      ngbTooltip="{{'bookingSummary' | translate:'guestEdit'}}"
                      tooltipClass="tooltip__container">
                  <svg class="ic ic_person">
                      <use href="assets/images/icons/sprite.svg#ic_person" />
                  </svg>
              </button>
            </div>

            <div>
              <button class="btn__icon btn__regular-icon--active-blue" (click)="changeGuestClient()"
                      ngbTooltip="{{'bookingSummary' | translate:'guestChange'}}"
                      tooltipClass="tooltip__container">
                  <svg class="ic ic_add_person">
                      <use href="assets/images/icons/sprite.svg#ic_add_person" />
                  </svg>
              </button>
            </div>

            <div>
               <button class="btn__icon btn__regular-icon--active-blue" ngbTooltip="{{'bookingSummary' | translate:'guestEmail'}}"
                       [disabled]="!bookingSummaryObj.guest?.clientemail"
                       tooltipClass="tooltip__container">
                  <a (click)="sendEmail(bookingSummaryObj.guest?.clientemail)" href="mailto:{{bookingSummaryObj.guest?.clientemail}}">
                      <svg class="ic ic_mail">
                          <use href="assets/images/icons/sprite.svg#ic_mail" />
                      </svg>
                  </a>
               </button>
            </div>

            <div>
               <button class="btn__icon btn__regular-icon--active-red"
                       (click)="viewGuestAccount()"
                       [disabled]="!guestAccountExists"
                       ngbTooltip="{{'bookingSummary' | translate:'guestAccountView'}}"
                       tooltipClass="tooltip__container">
                   <svg class="ic ic_calculator">
                       <use href="assets/images/icons/sprite.svg#ic_calculator" />
                   </svg>
               </button>
            </div>

          </div>
        </div>

        <div class="">
          <label class=" inputField__label">
            {{'bookingSummary'| translate:'guest2'}}
          </label>
          <div class="d-flex horizontal-gutter horizontal-gutter-medium">

            <input type="text" readonly
                   [value]="bookingSummaryObj.guest?.client2fullname ? bookingSummaryObj.guest?.client2fullname : ''"
                   ngbTooltip="{{bookingSummaryObj.guest?.client2notes}}" tooltipClass="tooltip__container"/>

            <div>
              <button class="btn__icon btn__regular-icon" ngbTooltip="{{'bookingSummary' | translate:'guestEdit'}}"
                      [disabled]="bookingSummaryObj.guest?.client2id === 0"
                      [ngClass]="{'btn__regular-icon--active-green': bookingSummaryObj.guest?.client2id !== 0 }"
                      (click)="editClient2(bookingSummaryObj.guest.client2id)"
                      tooltipClass="tooltip__container">
                  <svg class="ic ic_person">
                      <use href="assets/images/icons/sprite.svg#ic_person" />
                  </svg>
              </button>
            </div>

            <div>
              <button class="btn__icon btn__regular-icon--active-blue" ngbTooltip="{{'bookingSummary' | translate:'guestChange'}}"
                      (click)="changeGuest2Client()" tooltipClass="tooltip__container">
                  <svg class="ic ic_add_person">
                      <use href="assets/images/icons/sprite.svg#ic_add_person" />
                  </svg>
              </button>
            </div>

            <div>
               <button class="btn__icon btn__regular-icon--active-blue" ngbTooltip="{{'bookingSummary' | translate:'guestEmail'}}"
                       [disabled]="!bookingSummaryObj.guest?.client2email"
                       tooltipClass="tooltip__container">
                <a (click)="sendEmail(bookingSummaryObj.guest?.client2email)" href="mailto:{{bookingSummaryObj.guest?.client2email}}">
                    <svg class="ic ic_mail">
                        <use href="assets/images/icons/sprite.svg#ic_mail" />
                    </svg>
                </a>
              </button>
            </div>

            <div>
               <button class="btn__icon btn__regular-icon--active-blue"
                       [disabled]="bookingSummaryObj.guest?.client2id === 0"
                      (click)="clearGuest2Client()"
                      ngbTooltip="{{'bookingSummary' | translate:'guestClear'}}" tooltipClass="tooltip__container">
                   <svg class="ic ic_trash">
                       <use href="assets/images/icons/sprite.svg#ic_trash" />
                   </svg>
              </button>
            </div>

          </div>
        </div>
        <div class="d-flex horizontal-gutter">
          <div (click)="validateCheckIn()">
            <input class="form-check-input" type="checkbox" formControlName="checkedin"/>
            <label class="form-check-label  inputField__label">
              {{'bookingSummary'|translate:'checkedIn'}}
            </label>
          </div>
          <div (click)="validateCheckOut()">
            <input class="form-check-input" type="checkbox" formControlName='checkedout'/>
            <label class="form-check-label  inputField__label">
              {{'bookingSummary'|translate:'out'}}
            </label>
          </div>
        </div>
        <div class="d-flex justify-content-center gap-2 flex-wrap">
          <button class="btn__regular btn btn__primary flex-1 min-width-auto" (click)="guestCommunications()">
            {{'bookingSummary'|translate:'guestCommunications'}}
          </button>

          <div class="d-flex">
            <button class="btn__regular btn btn__primary" (click)="viewExtras()"
                    style="margin-left:auto;margin-right: 2px;">
              {{'bookingSummary'|translate:'extraView'}}
            </button>
            <div *ngIf="guestAccountExists" class="btn__badge">
              <sup class="btn__badge--icon">
                  <svg class="ic ic_bell absoluteCenter">
                      <use href="assets/images/icons/sprite.svg#ic_bell" />
                  </svg>
              </sup>
            </div>
          </div>
        </div>
      </div>

      <!-- right card -->
      <div class="d-flex flex-column col-4">

         <div class="modal-body d-flex horizontal-gutter">
            <div class="col-6 vertical-gutter d-flex flex-column">
               <span class="section-heading">
                  {{'bookingSummary'|translate:'accountInformation'}}
               </span>

               <button class="btn__regular btn btn__primary" (click)="viewInvoiceAccount()"
                       ngbTooltip="{{'bookingSummary'|translate:'viewAccountDetail'}}" tooltipClass="tooltip__container">
                  {{'bookingSummary'|translate:'viewAccount'}}
               </button>

               <button class="btn__regular btn btn__primary" (click)="viewRates()">
                  {{'bookingSummary'|translate:'viewRates'}}
               </button>

               <button class="btn__regular btn btn__primary" (click)="payOnCheckOut()"
                       *ngIf="bookingSummaryObj.gmEnabled === true">
                  {{'bookingSummary'|translate:'checkOut'}}
               </button>

              <bt-report-booking data-testid="invalidCC" [status]="invalidCreditCardStatus$" [reported]="invalidCCReported"
                (reportEvent)="reportInvalidCardConfirmation()">
                {{'bookingSummary'|translate: (invalidCCReported ? 'badCCReported': 'invalidCC')}}
                <span reported>{{'bookingSummary'|translate: 'badCCReported'}}</span>
              </bt-report-booking>

              <bt-report-booking data-testid="noShow" [status]="noShowStatus$" [reported]="noShowReported"
                (reportEvent)="reportNoShowConfirmation()">
                {{'bookingSummary'|translate: (noShowReported ? 'noShowReported': 'noShow')}}
                <span reported>{{'bookingSummary'|translate: 'noShowReported'}} <br> {{'bookingSummary'|translate: typeReported}}</span>
              </bt-report-booking>
            </div>

            <div class="vertical-bar"></div>

            <div class="col-5 vertical-gutter">
               <span class="section-heading">
                  {{'bookingSummary'|translate:'totals'}}
               </span>

               <div>
                  <label class=" inputField__label">
                     {{'bookingSummary'|translate:'accommodation'}}
                  </label>
                  <input formControlName='accomodationtotal' class="currencyTextAlign" readonly
                         (blur)="formatBookingAccount(bookingSummaryObj.bookingaccount.accomodationtotal,'accomodationtotal')"/>
               </div>

               <div>
                  <label class=" inputField__label">
                     {{'bookingSummary'|translate:'extras'}}
                  </label>
                  <input formControlName="extratotal" class="currencyTextAlign" readonly
                         (blur)="formatBookingAccount(bookingSummaryObj.bookingaccount.extratotal,'extratotal')"/>
               </div>

               <div>
                  <label class=" inputField__label">
                     {{'bookingSummary'|translate:'payments'}}
                  </label>
                  <input formControlName="payments" class="currencyTextAlign" readonly
                         (blur)="formatBookingAccount(bookingSummaryObj.bookingaccount.payments,'payments')"/>
               </div>
            </div>
         </div>

        <div class="modal-body d-flex flex-wrap" *ngIf="(tapNpayFeatureStatus$ | async) as tapNpayFeatureStatus">
          <div class="col-md-12 d-flex align-items-end gap-2 flex-wrap flex-lg-nowrap">
            <div class="col-6 max-w-200">
              <app-tap-n-pay
                classes="p-1 p-sm-2 h-40 ml-0 fw-semi-bold fs-14 border-radius-3"
                [setupPageUrl]="setupPageUrl"
                [paymentTapFeatureStatus]="tapNpayFeatureStatus"
                [details]="{
                  bbId: bookingSummaryObj.booking.bbid,
                  amount: 0,
                  bookingId: bookingSummaryObj.booking.bookingid,
                  mode: 'payment',
                  refundPaymentId: '',
                  currencyCode: getCurrency()
                }"
                [bookingDetails]="{
                  amountDue: 0,
                  accommodationTotal: bookingSummaryObj.bookingaccount.accomodationtotal,
                  extraTotal: bookingSummaryObj.bookingaccount.extratotal,
                  paymentsTotal: bookingSummaryObj.bookingaccount.payments,
                  clientFullName: bookingSummaryObj.madebyclient?.fullname,
                  fromDate: bookingSummaryObj.booking.fromdate,
                  toDate: bookingSummaryObj.booking.todate
                }"
              ></app-tap-n-pay>
            </div>
          </div>
        </div>

        <div class="modal-body d-flex flex-wrap">
          <div class="col-md-12 d-flex align-items-end gap-2 flex-wrap flex-lg-nowrap">
            <div class="col-md-12 col-lg-6 d-flex">
              <button class="btn__regular btn btn__primary flex-1 btn__fullWidth btn__deposit" (click)="calculateDeposit()">
                {{'bookingSummary'|translate:'depositCalc'}}
              </button>
            </div>
            <div class="full-width">
              <label class=" inputField__label">
                {{'bookingSummary'|translate:'deposit'}}
              </label>
              <input formControlName="displaydeposittotal" class="currencyTextAlign" (blur)="updateDeposit()" />
            </div>
          </div>
        </div>

        <div class="modal-body">
            <div class="d-flex justify-content-between typography__p" style="padding-bottom: 10px;">
                <a (click)="redirectToBookingPaymentStatus()">
                    <span class="bookingPaymentStatusUrl">
                        {{"bookingSummary" | translate:'bookingPaymentStatus'}}
                    </span>
                </a>
            </div>
              <div class="d-flex justify-content-between typography__p">
                <div>
                  <span class="subsection-heading">{{"bookingSummary" | translate:'entered'}}</span>
                  <span
                    class="text-nowrap typography__p">{{getLocalDateTime(bookingSummaryObj.booking?.bookingdate)}}</span>
                </div>
                <div>
                  <span class="subsection-heading">{{"bookingSummary" | translate:'type'}}</span>
                  <span class="text-nowrap typography__p">{{bookingSummaryObj.bookingtype}}</span>
                </div>
              </div>
          </div>
      </div>
    </div>
  </form>

  <div class="modal-footer d-flex justify-content-between">
    <div class="btn__container--secondary">
      <button type="button" class="btn__regular btn btn__warning" (click)="cancelBooking()">
        {{'bookingSummary'|translate:'bookingCancel'}}
      </button>
    </div>
    <div class="d-flex align-content-center">
      <div class="d-flex flex-column justify-content-center"
        [ngClass]="{'saveConfirmation':bookingSummaryObj.bookingsaved,'hidden':!bookingSummaryObj.bookingsaved}">
           <span>
              {{"bookingSummary" | translate: 'bookingSave'}}
           </span>
      </div>
      <button type="button" class="btn__regular btn btn__green" (click)="preSaveBooking()" [disabled]="hasInvalidInput()">
        {{'general'|translate:'save'}}
      </button>
    </div>
  </div>
</div>
