import {Component, Input} from '@angular/core';
import {TapNpayService} from './tap-n-pay.service';
import {PaymentTapFeatureStatus, TapNpayBookingDetails, TapNpayPaymentDetails} from './tap-n-pay.class';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-tap-n-pay',
    styleUrls: ['./tap-n-pay.component.scss'],
    templateUrl: './tap-n-pay.component.html'
})
export class TapNpayComponent {

    @Input() disabled = false;
    @Input() classes = '';
    @Input() bookingDetails?: TapNpayBookingDetails;
    @Input() details: TapNpayPaymentDetails = {bbId: 0, amount: 0, bookingId: 0, mode: 'payment', refundPaymentId: '', currencyCode: ''};
    @Input() setupPageUrl = '';
    @Input() paymentTapFeatureStatus: PaymentTapFeatureStatus;

    constructor(private tapNpayService: TapNpayService) {}

    initializeTapNpay() {
        const accommodationTotal = this.tapNpayService.sanitizeBookingAmount(this.bookingDetails?.accommodationTotal);
        const extraTotal = this.tapNpayService.sanitizeBookingAmount(this.bookingDetails?.extraTotal);
        const paymentsTotal = this.tapNpayService.sanitizeBookingAmount(this.bookingDetails?.paymentsTotal);

        let amountDue = 0;
        const {amount, bbId, bookingId, mode, refundPaymentId, currencyCode} = this.details;

        let fromDate = new Date().toString();
        let toDate = new Date().toString();
        let clientFullName = undefined;

        if (this.bookingDetails) {
          amountDue = Math.round(accommodationTotal + extraTotal - paymentsTotal);
          fromDate = this.bookingDetails.fromDate;
          toDate = this.bookingDetails.toDate;
          clientFullName = this.bookingDetails.clientFullName;
        }

        this.tapNpayService.startTapNPay({
          paymentDetails: {amount, bbId, bookingId, mode, refundPaymentId, currencyCode},
          bookingDetails: {amountDue, accommodationTotal, extraTotal, paymentsTotal, fromDate, toDate, clientFullName}
        })
    }

    navigateToSetup(){
      window.open(this.setupPageUrl);
    }
}
