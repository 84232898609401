import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TapNpayService} from '@shared/tap-n-pay/tap-n-pay.service';
import {interval, map, takeWhile} from 'rxjs';

@Component({
  selector: 'app-tap-n-pay-modal-qr',
  templateUrl: './tap-n-pay-modal-qr.component.html',
  styleUrls: ['./tap-n-pay-modal-qr.component.scss']
})
export class TapNPayModalQrComponent {

  @Input() qrCode: string = '';
  secondsLeft = 0;

  constructor(
    private tapNpayService: TapNpayService,
    private changeDetectorRef: ChangeDetectorRef,
    public activeModal: NgbActiveModal
  ) {}

  startTimer(seconds: number) {
    const timerInterval$ = interval(1000)
      .pipe(
        takeWhile(currentSeconds => currentSeconds <= seconds),
        map(currentSeconds => seconds - currentSeconds),
      ).subscribe((secondsLeft) => {
        this.secondsLeft = secondsLeft;
        if(secondsLeft === 0) {
          timerInterval$.unsubscribe();
          this.activeModal.close(false);
        }
      });
  }

  secondsToMinutes(seconds: number | null) {
    if (!seconds) return '';

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }
}