import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subject, takeUntil } from 'rxjs';
import { BookingSummaryService } from 'src/app/service/booking-summary.service';
import { BookingSummary } from 'src/app/service/models/BookingSummary';

@Component({
  selector: 'app-booking-room-info',
  templateUrl: './booking-room-info.component.html',
  styleUrls: ['./booking-room-info.component.scss']
})
export class BookingRoomInfoComponent implements OnDestroy  {
  bookingSummary$: Observable<BookingSummary>;
  destroy$: Subject<void> = new Subject<void>();
  isIconRotated: boolean = false;
  @Input() options;

  @Input() bookingInfoForm!: FormGroup;
  @Input() guestAccountExists: boolean;
  @Input() roomFilter: string;
  @Input() dateRange;

  @Output() dateRangeChanged = new EventEmitter();
  @Output() addRoom = new EventEmitter();
  @Output() changeRoom = new EventEmitter();
  @Output() splitRoom = new EventEmitter();
  @Output() cancelRoom = new EventEmitter();
  @Output() editClient = new EventEmitter();
  @Output() changeGuestClient = new EventEmitter();
  @Output() sendEmail = new EventEmitter();
  @Output() viewGuestAccount = new EventEmitter();
  @Output() editClient2 = new EventEmitter();
  @Output() changeGuest2Client = new EventEmitter();
  @Output() clearGuest2Client = new EventEmitter();
  @Output() validateCheckIn = new EventEmitter();
  @Output() validateCheckOut = new EventEmitter();
  @Output() guestCommunications = new EventEmitter();
  @Output() viewExtras = new EventEmitter();
  @Output() changeRoomView = new EventEmitter();
  @Output() toggleRates = new EventEmitter();

  constructor(private bookingSummaryService: BookingSummaryService) {
    this.bookingSummary$ = this.bookingSummaryService.bookingSummaryObs.pipe(
      takeUntil(this.destroy$)
    );
  }

  toggleIconRotation() {
    this.isIconRotated = !this.isIconRotated;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onInputChange(event: any) {
    const numericValue = event.target.value.replace(/[^0-9]/g, '');
    event.target.value = numericValue;
  }

  emitEditClient2(bookingSummary) {
    if(!(bookingSummary.guest?.client2id === 0)) {
      this.editClient2.emit(bookingSummary.guest.client2id)
    }
  }
}
