import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CurrencyDirective } from './currency.directive';
import { FocusDirective } from './focus.directive';
import { DatepickerDirective } from './datepicker.directive';
import { PercentDirective } from './percent.directive';
import { MediaDirective } from './media.directive';


@NgModule({
  declarations: [
    CurrencyDirective,
    FocusDirective,
    DatepickerDirective,
    PercentDirective,
    MediaDirective,
  ],
  exports: [
    CurrencyDirective,
    FocusDirective,
    DatepickerDirective,
    PercentDirective,
    MediaDirective,
  ],
  imports: [
    CommonModule
  ]
})
export class DirectivesModule { }
