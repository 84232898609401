<div [formGroup]="bookingInfoForm" class="p-xl-2 pt-sm-12 pt-xl-3 position-relative d-flex flex-column h-100">
    <div class="d-flex mb-2 gap-2">
        <div class="flex-3">
            <cui-input type="select" class="w-100x" [dropdownOptions]="statusCodes" dropdownOptionsLabel="description"
                dropdownOptionsValue="status" label="Status" [trailingIconColor]="'color-secondary-text'"
                [trailingIcon]="'assets/images/sprite.svg#keyboard-arrow-down'" formControlName="status"
                (change)="statusChanged.emit(bookingInfoForm.get('status').value)">
            </cui-input>
        </div>
    </div>

    <div class="d-flex mb-2 gap-2"
        *ngIf="bookingInfoForm.get('status').value == 'P' || bookingInfoForm.get('status').value == 'R'">
        <div class="flex-2">
            <x-cui-date-selector [viewMode]="2" dateText="Until" formControlName="statusdate" [readOnly]="true"
                [iconColor]="'color-mono-primary-text'">
            </x-cui-date-selector>
        </div>
        <div class="flex-1">
            <timepicker formControlName="statustime" [showSpinners]="false" [showMeridian]="false" class="h-48"
                ngbTooltip="{{ 'bookingSummary'| translate: 'hourMin'}}" tooltipClass="tooltip__container">
            </timepicker>
        </div>
    </div>

    <div class="d-flex mb-2 gap-2 flex-lg-row flex-sm-column">
        <div class="flex-3">
            <div class="position-relative">
                <cui-input mode="secondary" label="Booking Made By" class="w-100 d-none d-sm-block" [sizing]="'md'"
                    formControlName="madebyclientfullname" [readonly]="true">
                </cui-input>
                <cui-input mode="secondary" label="Booking Made By" class="w-100 d-block d-sm-none" [sizing]="'md'"
                    formControlName="madebyclientfullname" [readonly]="true">
                </cui-input>
            </div>
        </div>

        <div class="flex-1">
            <div class="d-flex flex-row gap-1 h-100 align-items-center">
              <cui-button classes="m-0 ic-16-svg ic-active-green" type="icon-filled" mode="success" sizing="sm" leadingIcon="person" (click)="editClient.emit(bookingSummary.madebyclient.clientid)" ngbTooltip="{{'bookingSummary' | translate:'clientEdit'}}" tooltipClass="tooltip__container"></cui-button>
              <cui-button classes="m-0 ic-change-client-svg ic-active-blue" type="icon-filled" mode="success" sizing="sm" leadingIcon="person-add" (click)="changeMadeByClient.emit()" ngbTooltip="Change Client" tooltipClass="tooltip__container"></cui-button>
              <cui-button classes="m-0 ic-email-guest-svg ic-active-blue" type="icon-filled" mode="success" sizing="sm" leadingIcon="email" (click)="sendEmail.emit(bookingSummary.madebyclient?.email)" [disabled]="!bookingSummary.madebyclient?.email" ngbTooltip="Email Client" tooltipClass="tooltip__container"></cui-button>
            </div>
        </div>
    </div>

    <div class="d-flex mb-2 flex-column extra-small-row gap-2">
        <div class="flex-1">
            <cui-input label="Reference" classes="h-48 w-100" formControlName="reference" [sizing]="'md'" placeholder="Enter your reference"></cui-input>
        </div>
    </div>

    <div class="d-flex gap-2 mb-2 flex-lg-row flex-sm-column">
        <div class="flex-2 d-flex align-items-center">
            <div class="d-flex align-items-center border border-1 rounded-3 color-neutral color-mono-primary-text w-100 h-100 border-radius-15 px-3 py-1"
                (click)="searchSourcesClicked(bookingSummary.booking.bookingtype)" role="button">
                <span class="me-2" *ngIf="!bookingSummary.booking.source">
                    <svg class="ic ic-22 ic_search ic_center">
                        <use href="assets/images/icons/sprite.svg#ic_search" />
                    </svg>
                </span>
                <span class="fs-14" *ngIf="!bookingSummary.booking.source">
                    Search for Source
                </span>
                <span class="fs-14" *ngIf="bookingSummary.booking.source">
                    {{bookingSummary.booking.source}}
                </span>
            </div>
        </div>

        <div class="flex-1">
            <cui-input label="Commission" formControlName="agentcomm" [sizing]="'md'"
                (input)="onCommissionInput($event)" (onBlur)="parseCommission()"></cui-input>
        </div>
    </div>

    <div class="mt-2 mb-2 mb-md-3 h-100">
        <cui-input type="textarea" classes="h-100" labelClasses="fw-semibold" formControlName="notes" label="Notes"
            [labelPosition]="'outside'" [toogleTextarea]="true" placeholder="Enter your notes here"
            [showCharacterCount]="false"></cui-input>
    </div>
</div>
