import {Component, HostListener, Injector, Input} from '@angular/core';
import {PercentPipe} from '@angular/common';
import {BookingSummaryComponent} from "../booking-summary/booking-summary.component";
import {TranslateService} from 'src/app/service/translate.service';
import {NgbTimepickerConfig} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {AccountService} from 'src/app/service/account.service';
import {BookingSummaryService} from 'src/app/service/booking-summary.service';
import {ConfirmationService} from 'src/app/service/confirmation.service';
import {CredentialService} from 'src/app/service/credential.service';
import {DatelibraryService} from 'src/app/service/datelibrary.service';
import {ModalPopupService} from 'src/app/service/modal-popup.service';
import {NbLibraryService} from 'src/app/service/nb-library.service';
import {PageRouteDataService} from 'src/app/service/page-route-data.service';
import {PageRouteService} from 'src/app/service/page-route.service';
import {ReportCommissionOption} from 'src/app/service/models/enum/booking-status.enum';
import {NoshowConfirmationComponent} from 'src/app/components/booking-info/widgets/noshow-confirmation/noshow-confirmation.component';
import {ClientsService} from 'src/app/service/clients.service';
import {BehaviorSubject, map, Subscription, tap} from 'rxjs';
import {ToastService} from '@nightsbridge/common-ui/toasts';
import {DisplayManagerService} from 'src/app/service/display-manager.service';
import {TapNpayService} from '@shared/tap-n-pay/tap-n-pay.service';
import {SimpleModalCloseOptions, SimpleModalContent} from '@shared/simple-modal/simple-modal.contract';
import {GridBreakpoints} from 'src/app/app.settings';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.scss'],
  providers: [PercentPipe]
})
export class BookingDetailsComponent extends BookingSummaryComponent implements SimpleModalContent {
  @Input() title = 'Booking Details';
  customStyles = {
    headerClass: 'border-bottom border-sm-bottom-0 mb-sm-0 mt-sm-1',
    titleContainerClass: 'justify-content-sm-center text-primary',
  };
  onCloseOptions: SimpleModalCloseOptions = {
    onModalClose: 'stackAwareClose',
    pagesInStackCondition: ['add-book', 'check-in-out']
  };

  private confirmService: ConfirmationService;

  activeTab: string = 'Booking info';
  closeObservable: Subscription;

  private screenWidth$ = new BehaviorSubject<number>(window.visualViewport?.width);
  isMobile$ = this.screenWidth$.pipe(
    map(width => width < GridBreakpoints.sm)
  );

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth$.next(window.visualViewport?.width);
  }

  constructor(
    injector: Injector
  ) {
    const bookingSummaryService = injector.get(BookingSummaryService);
    const toastr = injector.get(ToastrService);
    const cuiToastService = injector.get(ToastService);
    const translateService = injector.get(TranslateService);
    const confirmationService = injector.get(ConfirmationService);
    const clientsService = injector.get(ClientsService);
    const pageRouteService = injector.get(PageRouteService);
    const displayManagerService = injector.get(DisplayManagerService);

    super(clientsService, translateService, bookingSummaryService, confirmationService,
      injector.get(CredentialService), injector.get(PageRouteDataService), injector.get(PageRouteService),
      injector.get(DatelibraryService), injector.get(AccountService), injector.get(PercentPipe),
      injector.get(NgbTimepickerConfig), injector.get(ModalPopupService), injector.get(NbLibraryService),
      toastr, injector.get(ToastService), injector.get(TapNpayService));

    this.confirmService = confirmationService;

    bookingSummaryService.bookingSummaryObj.bookingSaved$.subscribe((bookingSaved) => {
      const hasChanges = this.bookingSummaryForm.dirty;
      if (hasChanges && bookingSaved) {
        cuiToastService.show(
          'Successful',
          translateService.translate('bookingSummary', 'bookingSave'),
          {classname: 'toast-success', delay: 4000, positionClass: 'toast-bottom-right'});
      }
    });

    this.closeObservable = pageRouteService.closeObservable
      .subscribe((returnData) => {
        if (returnData?.activeTab) {
          this.activeTab = returnData.activeTab;
        } else {
          displayManagerService.setCalendarVisibility(false);
        }
      });

  }

  ngOnInit(): void {
    super.ngOnInit();
    this.screenWidth$.next(window.visualViewport?.width);
  }

  reportNoShowConfirmation() {
    this.confirmService.custom(NoshowConfirmationComponent, {
      title: 'No Show',
      buttons: [{
        text: 'waiveCommission',
        class: 'btn__warning',
        callback: () => this.reportNoShow(ReportCommissionOption.Waive)
      }, {
        text: 'acceptCommission',
        class: 'btn__primary',
        callback: () => this.reportNoShow(ReportCommissionOption.Accept)
      }]
    });
  }
}
