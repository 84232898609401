<div class="booking-details-container d-flex flex-column h-100">
  <ng-container *ngIf="isMobile$ | async; then mobileView else desktopView"></ng-container>

  <!-- Shared component templates -->
  <ng-template #bookingInfoTemplate>
    <app-booking-room-info [options]="options" [guestAccountExists]="guestAccountExists"
      [bookingInfoForm]="bookingSummaryForm" [roomFilter]="roomFilter" [dateRange]="dateRange" (addRoom)="addRoom()"
      (changeRoom)="changeRoom()" (splitRoom)="splitRoom()" (cancelRoom)="cancelRoom()"
      (editClient)="editClient($event)" (changeGuestClient)="changeGuestClient()" (sendEmail)="sendEmail($event)"
      (viewGuestAccount)="viewGuestAccount()" (editClient2)="editClient2($event)"
      (changeGuest2Client)="changeGuest2Client()" (validateCheckIn)="validateCheckIn()"
      (validateCheckOut)="validateCheckOut()" (dateRangeChanged)="dateRangeChanged($event)"
      (guestCommunications)="guestCommunications()" (changeRoomView)="changeRoomView($event)"
      (viewExtras)="viewExtras()" (toggleRates)="toggleRates()" (clearGuest2Client)="clearGuest2Client()">
    </app-booking-room-info>
  </ng-template>

  <ng-template #roomInfoTemplate>
    <app-booking-info-summary class="h-100" [bookingInfoForm]="bookingSummaryForm" [bookingSummary]="bookingSummaryObj"
      [statusCodes]="statuscodes" [statuscodeMap]="statuscodeMap" (statusChanged)="statusChanged($event)"
      (editClient)="editClient($event)" (changeMadeByClient)="changeMadeByClient()" (sendEmail)="sendEmail($event)"
      (searchSources)="searchSources()" (openUntilModal)="openUntilModal()">
    </app-booking-info-summary>
  </ng-template>

  <ng-template #accountInfoTemplate>
    <app-account-info
      (viewAccountClicked)="viewInvoiceAccount()"
      (viewRatesClicked)="viewRates()"
      (checkoutClicked)="payOnCheckOut()"
      (invalidccClicked)="reportInvalidCardConfirmation()"
      (cancelBookingComClicked)="reportCancelBookingComConfirmation()"
      (noShowClicked)="reportNoShowConfirmation()"
      (calculateDepositClicked)="calculateDeposit()"
      (updateDeposit)="updateDeposit()"
      (viewStatusAndHistoryClicked)="redirectToBookingPaymentStatus()"
      [reportCancelBookingCom]="cancelBookingStatus"
      [bookingSummaryObject]="bookingSummaryObj"
      [bookingSummaryForm]="bookingSummaryForm"
      [reportInvalidCCBookingStatus]="invalidCreditCardStatus$"
      [invalidCCReported]="invalidCCReported"
      [reportNoShowStatus]="noShowStatus$"
      [noShowReported]="noShowReported"
      [typeReported]="typeReported">
    </app-account-info>
  </ng-template>

  <!-- Mobile view with tabs -->
  <ng-template #mobileView>
    <app-simple-tabs class="side-panel-tabs flex-grow-1 overflow-auto">
      <ng-template appTab title="Guest Details" [active]="activeTab == 'Guest Details'">
        <ng-container *ngTemplateOutlet="bookingInfoTemplate"></ng-container>
      </ng-template>
      <ng-template appTab title="Booking Notes" [active]="activeTab == 'Booking Notes'">
        <ng-container *ngTemplateOutlet="roomInfoTemplate"></ng-container>
      </ng-template>
      <ng-template appTab title="Account" [active]="activeTab == 'Account'">
        <ng-container *ngTemplateOutlet="accountInfoTemplate"></ng-container>
      </ng-template>
    </app-simple-tabs>
  </ng-template>

  <!-- Desktop view with flex layout -->
  <ng-template #desktopView>
    <div class="d-flex gap-2 flex-1 m-sm-2 mt-sm-0">
      <div class="flex-1 p-2 card">
        <div class="card-header no-x-padding d-flex flex-md-column flex-lg-row overflow-border ls-tight justify-content-between">
          <h5 class="mb-0 text-primary fw-bold fs-6 fs-lg-18 text-nowrap">Guest Details</h5>
          <app-booking-id-pills class="d-sm-none d-flex d-md-flex flex-md-column ms-2 max-pill-width" [NBId]="bookingSummaryObj.booking.bookingid"
            [bookingId]="bookingSummaryObj.booking.bbbookingid"> </app-booking-id-pills>
        </div>
        <ng-container *ngTemplateOutlet="bookingInfoTemplate"></ng-container>
      </div>
      <div class="flex-1 p-2 card">
        <div class="card-header no-x-padding overflow-border ls-tight">
          <h5 class="mb-0 text-primary fw-bold fs-6 fs-lg-18">Booking Notes</h5>
        </div>
        <ng-container *ngTemplateOutlet="roomInfoTemplate"></ng-container>
      </div>
      <div class="flex-1 p-2 card">
        <div class="card-header no-x-padding overflow-border ls-tight">
          <h5 class="mb-0 text-primary fw-bold fs-6 fs-lg-18">Account</h5>
        </div>
        <ng-container *ngTemplateOutlet="accountInfoTemplate"></ng-container>
      </div>
    </div>
  </ng-template>

  <!-- Footer buttons -->
  <div class="button-container d-flex p-2 px-3 gap-1 border-top mt-auto rounded-bottom">
    <xcui-button type="tonal" mode="cancel" class="flex-1 flex-sm-none h-40" classes="min-w-120 no-wrap" (click)="cancelBooking()">
      Cancel
    </xcui-button>
    <span class="flex-none flex-sm-1"></span>
    <xcui-button type="tonal" mode="success" class="flex-1 flex-sm-none h-40" classes="min-w-120" (click)="preSaveBooking()" [disabled]="hasInvalidInput()">
      Save
    </xcui-button>
  </div>
</div>
