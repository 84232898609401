import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {TranslateService} from 'src/app/service/translate.service';
import {Constants} from 'src/app/service/models/enum/constants.enum';
import {Items} from 'src/app/service/models/Items';
import {DisplayManagerService} from 'src/app/service/display-manager.service';
import {Account} from "../../../../../service/models/Account";
import {AccountSummary} from "../../../../../service/models/AccountSummary";
import {CredentialService} from "../../../../../service/credential.service";
import {Subject, takeUntil} from "rxjs";
import {TapNpayService} from "@shared/tap-n-pay/tap-n-pay.service";
import {environment} from '../../../../../../environments/environment';
import {HttpParams} from '@angular/common/http';
import {HttpInterceptorService} from '../../../../../service/http-interceptor.service';

const TAP_N_PAY_SETUP_URL = environment.nbDashboard + '/integrations/tap-n-pay';

@Component({
  selector: 'app-account-summary-items-list',
  templateUrl: './account-summary-items-list.component.html',
  styleUrls: ['./account-summary-items-list.component.scss']
})
export class AccountSummaryItemsListComponent implements OnDestroy {

  private destroy$ = new Subject<void>();

  @Input() account: Account;
  @Input() items: AccountSummary['items'];
  @Output() viewItem = new EventEmitter();
  @Output() addPayment = new EventEmitter();
  @Output() generateCcLink = new EventEmitter();

  setupPageUrl = TAP_N_PAY_SETUP_URL;
  tapNpayFeatureStatus$ = this.tapNpayService.tapNpayFeatureStatus$;

  constructor(
    public translateService: TranslateService,
    private displayManagerService: DisplayManagerService,
    private credentialService: CredentialService,
    private tapNpayService: TapNpayService,
    private httpInterceptor: HttpInterceptorService
  ) {
    this.setRedirectToPaymentTapSetupURL();
  }

  getAmount(amount: any) {
    if (Number(amount) === 0) { return "-"; }
    return amount;
  }

  getToolTipForAccountItem(item: Items) {
    if (item.itemtype === Constants.LINE_ITEM_PAYMENT) {
      return this.translateService.translate('accountSummary', 'lineItemPaymentTooltip');
    } else if (item.itemtype === Constants.LINE_ITEM_CAPS_EXTRA) {
      return this.translateService.translate('accountSummary', 'lineItemCapsExtraTooltip');
    } else {
      return this.translateService.translate('accountSummary', 'lineItemTooltip');
    }
  }

  isMobile() {
    return this.displayManagerService.isMobile();
  }

  getBookingIdFromItems(items: AccountSummary['items']) {
    const bookingItems = items.filter(item => item.itemtype === Constants.LINE_ITEM_BOOKING);
    const lastItemIndex = bookingItems.length - 1;
    return bookingItems[lastItemIndex]?.itemid || null
  }

  getCurrency() {
    return this.credentialService.getCurrency;
  }

  setRedirectToPaymentTapSetupURL() {
    const data = {
      messagename: 'PaymentTapSignUpRQ',
      credentials: {loginkey: this.credentialService.getLoginKey},
      bbid: this.credentialService.getBBID,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    this.httpInterceptor.postRequest(environment.apiUrl + '/bridgeitapi', body).pipe(takeUntil(this.destroy$)).subscribe(response => {
        this.setupPageUrl = response.data.redirectUrl;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
