import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { LoadingService } from './service/loading.service';
import { loadAnimation } from './animations';
import { CredentialService } from './service/credential.service';
import { Meta } from '@angular/platform-browser';
import {catchError, fromEvent, Observable, of, Subject, Subscription, switchMap, takeUntil} from 'rxjs';
import { OnlineStatusService, OnlineStatusType } from 'ngx-online-status';
import {delay} from 'rxjs/operators';
import {InterceptorLoadingService} from './service/interceptor-loading.service';
import {PanelContainerService} from './service/panel-container.service';
import {PanelContainerComponent} from './shared/panel/panel-container/panel-container.component';
import {isMobileDevice, isOnRootCalendarUrl} from 'src/utils';
import {Router} from '@angular/router';
import {TapNpayService} from "@shared/tap-n-pay/tap-n-pay.service";
import {ToastService} from "@nightsbridge/common-ui/toasts";
import {GoogleAnalyticsService} from "./service/google-analytics.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [loadAnimation]
})
export class AppComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();

  title = 'Calendar-UI';
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  status: OnlineStatusType; //Enum provided by ngx-online-status
  onlineStatusCheck: any = OnlineStatusType;
  loading: boolean = false;

  @ViewChild(PanelContainerComponent) panelContainer: PanelContainerComponent;

  constructor(
    private loadingService: LoadingService,
    private credentialService: CredentialService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private metaService: Meta,
    private interceptorLoadingService: InterceptorLoadingService,
    private panelContainerService: PanelContainerService,
    private tapNpayService: TapNpayService,
    private router: Router,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    const self = this;
    setTimeout(function () {
      self.loadingService.hideLoadingScreen();
    }, 500);
    this.listenToLoading();
    this.checkTapNpayFeatureStatus();
  }

  ngAfterViewInit() {
    const currentScreenWidth = window.innerWidth;
    this.setMinViewport(currentScreenWidth);
    this.panelContainerService.setPanelContainerInstance(this.panelContainer);
  }

  setMinViewport(currentScreenWidth) {
    const currentRoute = this.router.url;

    if (isOnRootCalendarUrl() || (!isMobileDevice() && currentScreenWidth < 1300)) {
      this.metaService.updateTag({name: 'viewport', content: 'width=1300, initial-scale=1'});
    }
  }

  get showLoading() {
    return this.loadingService.isLoadingScreenShown;
  }

  get isAuthenticated() {
    return this.credentialService.getLoginKey;
  }

  /**
   * Listen to the loadingSub property in the LoadingService class. This drives the
   * display of the loading spinner.
   */
  listenToLoading(): void {
    this.interceptorLoadingService.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
        this.loadingService.toggleLoadingScreen(loading);
      });
  }

  checkTapNpayFeatureStatus() {
    this.credentialService.getCurrentUser.pipe(
      takeUntil(this.destroy$),
      switchMap((user) => {
        if (!user?.loginkey) return of(null);
          const {bbid, loginkey} = user;
          return this.tapNpayService.checkTapNpayFeatureStatus({bbid, loginkey});
      }),
      catchError((error) => of(null))
    ).subscribe((toastNotifications$) => {
      if (toastNotifications$) {
        toastNotifications$.subscribe((toastNotifications => {
          if (toastNotifications.type === 'approved') {
            this.toastService.show('Successful', toastNotifications.message, {classname: 'toast-success'});
          }

          if (toastNotifications.type === 'declined') {
            this.toastService.show("Alert", toastNotifications.message, {
              classname: "toast-error",
            });
          }
        }))
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
