import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';

import {CredentialService} from './credential.service';
import {environment} from "../../environments/environment";
import {HttpInterceptorService} from './http-interceptor.service';
import {BookingSummary} from './models/BookingSummary';
import {BehaviorSubject, Observable} from 'rxjs';
import {DatelibraryService} from './datelibrary.service';
import {DateRange} from './models/DateRange';
import {AccountService} from './account.service';
import {TranslateService} from './translate.service';
import {GuestDetail} from './models/Guest';
import {NbLibraryService} from './nb-library.service';
import {ReportCommissionOption} from './models/enum/booking-status.enum';
import {BookingComResponse} from './models/CancelBookingCom';

@Injectable({
  providedIn: 'root'
})
export class BookingSummaryService {

  bookingSummaryObj: BookingSummary = new BookingSummary();
  _bookingSummaryObs: BehaviorSubject<BookingSummary>;

  constructor(
    private authService: CredentialService,
    private httpInterceptor: HttpInterceptorService,
    private datelibraryService: DatelibraryService,
    private accountService: AccountService,
    private translateService: TranslateService,
    private nbLib: NbLibraryService) {
    this.bookingSummaryObj.dateRange = new DateRange();
    this._bookingSummaryObs = <BehaviorSubject<BookingSummary>>new BehaviorSubject<BookingSummary>(this.bookingSummaryObj);
  }

  get bookingSummaryObs() {
    return this._bookingSummaryObs.asObservable();
  }

  setBookingSubData(bookingData) {
    this.bookingSummaryObj = this.formatData(this.bookingSummaryObj, bookingData.data);
    this._bookingSummaryObs.next(this.bookingSummaryObj);
  }

  calculateDeposit(bookingid) {
    const data = {
      messagename: 'BookingDepositRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      bookingid: bookingid,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest(environment.apiUrl + '/bridgeitapi', body);
  }

  getBookingData(bookingid) {
    const data = {
      messagename: 'BookingSummaryRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      bookingid: bookingid,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest(environment.apiUrl + '/bridgeitapi', body).pipe(map(response => {
      this.setBookingSubData(response);
      return this.bookingSummaryObj;
    }));
  }

  splitBooking(bookingid, guestid) {
    const data = {
      messagename: 'BookingSplitRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      bookingid: bookingid,
      guestid: guestid,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest(environment.apiUrl + '/bridgeitapi', body);
  }

  getGuestCommunications(guest, bbid, bookingid, checkClientRegistered) {
    const data = {
      messagename: 'GuestCommunicationsRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      guest: guest,
      bbid: bbid,
      bookingid: bookingid,
      checkClientRegistered: checkClientRegistered
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest(environment.apiUrl + '/bridgeitapi', body);
  }

  saveBooking(booking, bookingnote, guests, ratesupdate, bdateschanged) {
    const data = {
      messagename: 'BookingSaveRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      booking: booking,
      bookingnote: bookingnote,
      guests: guests,
      ratesupdate: ratesupdate,
      bdateschanged: bdateschanged,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest(environment.apiUrl + '/bridgeitapi', body);
  }

  redirectToBookingPaymentStatus(booking) {
    const data = {
      messagename: 'BookingPaymentStatusRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      bookingid: booking.bookingid,
      bbid: booking.bbid,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest(environment.apiUrl + '/bridgeitapi', body);
  }

  cancelBooking(bookingid, reason) {
    const data = {
      messagename: 'BookingCancelRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      bookingid: bookingid,
      reason: reason,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest(environment.apiUrl + '/bridgeitapi', body);
  }

  checkMultipleBooking(bookingid, accountid) {
    const data = {
      messagename: 'MultipleBookingCheckRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      bookingid: bookingid,
      accountid: accountid,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest(environment.apiUrl + '/bridgeitapi', body);
  }

  updateStatusMultipleBooking(accountid, bookingid, status) {
    const data = {
      messagename: 'MultipleBookingStatusUpdateRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      accountid: accountid,
      bookingid: bookingid,
      status: status,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest(environment.apiUrl + '/bridgeitapi', body);
  }

  cancelRoom(bookingid, guestid, reason) {
    const data = {
      messagename: 'BookingRoomCancelRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      bookingid: bookingid,
      guestid: guestid,
      reason: reason,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest(environment.apiUrl + '/bridgeitapi', body);
  }

  getInvalidCreditCardStatus(bookingid) {
    const data = {
      messagename: 'ValidateCreditCardRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      bookingid: bookingid
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest('/bridgeitapi', body);
  }

  reportInvalidCreditCard(bookingid) {
    const data = {
      messagename: 'ReportInvalidCreditCardRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      bookingid: bookingid
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest('/bridgeitapi', body);
  }

  getBookingCancellationStatus(bookingid) {
    const data = {
      messagename: 'BookingComReportButtonPageInitRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      bookingid: bookingid
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest('/bridgeitapi', body);
  }

  cancelBookingCom(bookingid): Observable<BookingComResponse> {
    const data = {
      messagename: 'BookingComReportReservationCancellationRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      bookingid: bookingid,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest('/bridgeitapi', body);
  }

  getNoShowStatus(bookingid) {
    const data = {
      messagename: 'GuestNoShowRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      bookingid: bookingid
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest('/bridgeitapi', body);
  }

  reportNoShow(bookingid, reportCommissionOption: ReportCommissionOption) {
    const data = {
      messagename: 'ReportGuestNoShowRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      bookingid: bookingid,
      reportCommissionOption: reportCommissionOption
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest('/bridgeitapi', body);
  }

  formatBookingAccount(value, config) {
    if (isNaN(Number(value))) {
      return;
    }

    if (typeof value !== 'number') {
      value = Number(value);
    }
    return this.nbLib.formatNumber(value.toFixed(2), this.bookingSummaryObj.config.currencySeparator);
  }

  formatDeposit(bookingSummaryAccount, config) {
    let bookingValue = bookingSummaryAccount.deposittotal && bookingSummaryAccount.deposittotal > 0 && typeof bookingSummaryAccount.deposittotal === 'number' ?
      bookingSummaryAccount.deposittotal.toFixed(2) : (bookingSummaryAccount.deposittotal? bookingSummaryAccount.deposittotal : '0.00');
    let paymenttotal = bookingSummaryAccount.paymenttotal && bookingSummaryAccount.paymenttotal > 0 && typeof bookingSummaryAccount.paymenttotal === 'number' ?
      bookingSummaryAccount.paymenttotal.toFixed(2) : (bookingSummaryAccount.paymenttotal? bookingSummaryAccount.paymenttotal : '0.00');
    let outstandingbalance = bookingSummaryAccount.outstandingbalance && bookingSummaryAccount.outstandingbalance > 0 && typeof bookingSummaryAccount.outstandingbalance === 'number' ?
      bookingSummaryAccount.outstandingbalance.toFixed(2) : (bookingSummaryAccount.outstandingbalance? bookingSummaryAccount.outstandingbalance : '0.00');
    bookingSummaryAccount.displaydeposittotal = this.nbLib.formatCurrency(bookingValue);
    bookingSummaryAccount.paymenttotal = this.nbLib.formatCurrency(paymenttotal);
    bookingSummaryAccount.outstandingBalance = this.nbLib.formatCurrency(outstandingbalance);
    return bookingSummaryAccount
  }

  formatData(bookingSummaryObj, bookingData?) {
    bookingSummaryObj = bookingSummaryObj !== undefined ? bookingSummaryObj : new BookingSummary();
    bookingSummaryObj.chatallowed = bookingData.chatallowed;
    bookingSummaryObj.booking = bookingData.booking;
    bookingSummaryObj.invoicetoclient = bookingData.invoicetoclient;
    bookingSummaryObj.madebyclient = bookingData.madebyclient;
    bookingSummaryObj.bookingaccount = this.formatDeposit({...bookingData.bookingaccount, outstandingbalance: bookingData.outstandingbalance, paymenttotal: bookingData.paymenttotal}, bookingSummaryObj.config);
    bookingSummaryObj.account = bookingData.account;
    bookingSummaryObj.guests = bookingData.guests;
    bookingSummaryObj.guest = bookingSummaryObj.guests[0];
    bookingSummaryObj.selectedguest = new GuestDetail();
    bookingSummaryObj.clientiseditable = true;
    bookingSummaryObj.roomsize = bookingSummaryObj.guests.length - 1; // the index starts from 0, so minus one
    bookingSummaryObj.establishment = bookingData.establishment;
    bookingSummaryObj.bookingnote = bookingData.bookingnote;
    bookingSummaryObj.reservedDate = bookingData.reserved;
    bookingSummaryObj.guest = bookingSummaryObj.guests[0];
    bookingSummaryObj.selectedguest = {};
    bookingSummaryObj.clientiseditable = true;
    bookingSummaryObj.roomsize = bookingSummaryObj.guests.length - 1; // the index starts from 0, so minus one
    bookingSummaryObj.bookingaccount.displaydeposittotal = bookingSummaryObj.bookingaccount.deposittotal + '';
    bookingSummaryObj.dateRange = {
      startdate: this.datelibraryService.getDateObject(bookingData.booking.fromdate),
      enddate: this.datelibraryService.getDateObject(bookingData.booking.todate),
      duration: this.datelibraryService.daysDiff(new Date(bookingData.booking.fromdate), new Date(bookingData.booking.todate))
    };
    bookingSummaryObj.guests.forEach((guest, index) => {
      if (guest.guestid === bookingSummaryObj.selectedguest.guestid) {
        bookingSummaryObj.selectedguestIndex = index;
      }
    });
    bookingSummaryObj.madebyclient.fullname = this.accountService.getClientFullName(bookingSummaryObj.madebyclient);
    bookingSummaryObj.invoicetoclient.fullname = this.accountService.getClientFullName(bookingSummaryObj.invoicetoclient);

    // Format the entry type
    if (bookingSummaryObj.booking.bookingtype === 'P') {
      bookingSummaryObj.booking.bookingtypedisplay = this.translateService.translate('bookingSummary', 'bookingPc');
    } else {
      bookingSummaryObj.booking.bookingtypedisplay = this.translateService.translate('bookingSummary', 'bookingChannel');
    }
    if (+bookingSummaryObj.establishment.childage1 !== 0) {
      bookingSummaryObj.showFirstChildAgeGap = true;
    }

    if (bookingSummaryObj.establishment.childage2 !== 0) {
      bookingSummaryObj.showSecondChildAgeGap = true;
    }
    bookingSummaryObj.hasExtras = false;

    bookingSummaryObj.guests.forEach((guest) => {
      if (guest.hasextras) {
        bookingSummaryObj.hasExtras = true;
      }
    });

    bookingSummaryObj.bookingtype = this.translateService.translate('bookingSummary', bookingSummaryObj.booking.bookingtype);
    bookingSummaryObj.showsourcereplace = bookingSummaryObj.booking.bookingtype === 'P';


    return this.bookingSummaryObj;
  }
}
